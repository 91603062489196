import React from "react";
import whatsappIcon from "../../src/images/whatsappIcon.svg";
import Landing from "./Landing";
import Features from "./Features";
import LifeStory from "./LifeStory";
import News from "./News";
import Reach from "./Reach";
import Contact from "./Contact";
import Funding from "./Funding";

export const Home = () => {
  return (
    <div>
      <Landing />
      <Reach />
      {/* <Funding /> */}
      {/* <LifeStory celebrity={true} /> */}
      <Features />

      <LifeStory celebrity={false} />
      <News />

      {/* <Contact /> */}
    </div>
  );
};

export default Home;
