import React,{useState,useEffect} from "react";
//import {Link} from 'react-router-dom';
import ClassplusLogo from "../images/classplusLogo.svg";
import { ScrollTo } from "react-scroll-to";

export const Navigation = () => {
  const handleCTAScroll=()=>{
      if(window.innerWidth > 700) {
        window.scrollTo(0,0);
      }
      else{
        console.log(";;")
        window.scrollTo(0,355);
      }
      window.gtag('event', 'Sell_Across_Btn_Click');

  }

  let [offset,setOffset]= useState(false)

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll, true);
  },[]);

  const handleScroll = () => {
    setOffset(window.pageYOffset);
  };

  return (
    <div>
      <div className="blueStrip"></div>
      <div className="navigation">
        <div className="navigation__logo">
          <a href="/">
            {" "}
            <img
              src={ClassplusLogo}
              alt="Classplus"
              className="navigation__logo--img"
            />
          </a>
        </div>
        <div className="navigation__book">
           {(window.innerWidth > 700 && offset>700) ||  (offset >870 || offset<350  && window.innerWidth<700)? <button
                className="btn--bookADemo btn scrollButton"
                onClick={()=>{handleCTAScroll()}}
                >
                    Sell across India
              </button>
              :""
              }
          {/* <ScrollTo>
            {({ scrollTo }) => (
              <button
                className="btn--bookADemo btn scrollButton"
                onClick={() => {
                  window.innerWidth > 600
                    ? window.innerWidth > 1400
                      ? window.innerWidth > 1600
                        ? scrollTo({ y: 300, smooth: true })
                        : scrollTo({ y: 300, smooth: true })
                      : scrollTo({ y: 300, smooth: true })
                    : window.innerWidth < 1300
                    ? window.innerWidth < 600
                      ? scrollTo({ y: 3800, smooth: true })
                      : scrollTo({ y: 3600, smooth: true })
                    : scrollTo({ y: 3800, smooth: true });
                }}
              >
                Sell across India
              </button>
            )}
          </ScrollTo> */}
          {
            // <button className="btn--bookADemo btn" >Book a Demo</button>
          }
        </div>
      </div>
    </div>
  );
};

export default Navigation;
