import React from "react";

export const Reach = () => {
  return (
    <div className="Reach">
      <div className="Reach__content grid--5">
        <div className="Reach__content--Para">Educators on Classplus have earned over Rs. 100 Cr</div>
        <div className="Reach__content--Para">by selling their study material through their own app</div>
        {/* <div className="Reach__content--item">
          <div className="Reach__content--item--heading heading--mega">
            1,100+ Cities
          </div>
          {
            //     <div className="Reach__content--item--content">
            //     Cities
            // </div>
          }
        </div>
        <div className="Reach__content--item">
          <div className="Reach__content--item--heading heading--mega tutorsMobile">
            50,000+ Teachers
          </div>
          {
            //     <div className="Reach__content--item--content" style={{"text-align":"center","margin-right":"2.4rem"}}>
            //     Tutors
            // </div>
          }
        </div>
        <div className="Reach__content--item">
          <div className="Reach__content--item--heading heading--mega">
            70Lakh+ Students
          </div>
          {
            //     <div className="Reach__content--item--content">
            //   Users
            // </div>
          }
        </div> */}
      </div>
    </div>
  );
};

export default Reach;
