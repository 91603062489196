import React from 'react'
import Carousel from './Carousel';
//import FeaturesList from './FeaturesList';
//import {Link} from 'react-router-dom';


export const Features = () => {
    return(
        <div className="features">
           {
//                 <div className="features__header">
//                 <div className="features__header--heading heading--mega">
//                    A few reasons why customers love us
//                 </div>
//                 <div className="features__header--content p--medium green--text">
//                    There are multiple utilities for a coaching institute. Our mission is to make each one of them possible through one single platform.
//  Take a quick look at how we help institutes of all sizes grow bigger, grow faster!
//                 </div>
//             </div>
        }
            <div className="features__content">
                <Carousel />
               {
                  //  <FeaturesList />
                }
            </div>
            <div className="features__link">
               {
                  //  <Link to="/features">Learn more about Classplus features</Link>
                }
            </div>
        </div>
    )
}

export default Features