import React from 'react'
import Form from './Form';
export const Contact = () => {
    return (
      <div className="Contact" id="second">
        <div className="Contact--text">
          <div className="Contact__heading">
            Want to grow your revenue by 10x?
          </div>
          <div className="Contact__content p--medium green--text">
            Experienced and Trusted by 300,000+ users across India. <br />
            Request a free demo to see how our technology can boost your online
            business.
          </div>
        </div>
        <div className="Contact--form">
          <Form source="contact" />
        </div>
      </div>
    );
}

export default Contact