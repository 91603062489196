import React from 'react'

import Store1 from '../images/Store1.svg';
import Store2 from '../images/Store2.svg';
import Store3 from '../images/Store3.svg';
import Store4 from '../images/Store4.svg';
import Store5 from '../images/Store5.svg';
import Store6 from '../images/Store6.svg';




export const FeaturesList = () => {
    return (
        <div className="features__cheat">
            <div className="features__header--heading heading--medium tACenter">
                  Boost your online brand using our powerful features
                 </div>
                <div className="features__content--list grid--10">
                    
                    <div className="features__content--list--item">
                        <img src={Store1} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                           Create Online Courses
                        </p>
                        <p className="features__content--list--item--content p--small  ">
                       Easily create your online courses and sell on your mobile app
                        </p> 

                    </div>
                    <div className="features__content--list--item">
                        <img src={Store2} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                           Secured Video Selling
                        </p> 
                        <p className="features__content--list--item--content p--small">
Forget worrying about content piracy on our secured platform 
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={Store3} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                           Conduct Online Tests
                        </p>
                        <p className="features__content--list--item--content p--small">
Create and host your test series in a hassle-free manner
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={Store4} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                           Unlimited Cloud Space
                        </p>
                        <p className="features__content--list--item--content p--small">
We provide you infinite storage space on cloud for your content 
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={Store5} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
Safe & Secure
Payments
                        </p>
                        <p className="features__content--list--item--content p--small">
Automated payment gateway to ensure fast and secured collections
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={Store6} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                           Data Security
                        </p>
                        <p className="features__content--list--item--content p--small">
Your courses are encrypted to provide maximum security 
                        </p>

                    </div>

                    
                  
                </div>
        </div>
          
    )
}

export default FeaturesList