import React, { Component } from "react";
import Form from "./Form";
import Background from "../images/background.svg";
import BackgroundSmall from "../images/BackgroundSmall2.svg";
import Illustrate from "../images/ChangedIllustration.svg";

import NewLogo from "../images/newLogo.png";
//import BackgroundBhopal from "../images/Bhopal.jpg";
// import MobileBanner from "../images/MobileBannerInde.jpg";
import MobileBanner from "../images/bgNewMobile.jpg";
// import BackgroundImageNew from "../images/WebBannerInde.gif";
import BackgroundImageNew from "../images/bgNew.jpg";
import whatsappIcon from "../images/whatsapp.svg";
import { ScrollTo } from "react-scroll-to";

class Landing extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    // fetch('https://skynet.classplusapp.com/api/v1/redirect/lead-image/book-a-demo-landing-image',{
    //     method:'GET',?utm_source=google&utm_medium=email&utm_campaign=kuchBhi

    // }).then(
    //     this.setState({
    //         imageUrl:
    //     })
    // )

    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
      m,
      key,
      value
    ) {
      vars[key] = value;
    });

    this.setState({
      utm_source: vars["utm_source"],
      utm_medium: vars["utm_medium"],
      utm_campaign: vars["utm_campaign"],
    });
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions());
  }
  render() {
    const background = () => {
      if (window.innerWidth < 600) {
        return {
          backgroundImage: `url(${MobileBanner})`,
          width: "100%",
          backgroundSize: "cover",
          height: "80vh",
          backgroundPosition: "bottom",
        };
      } else {
        return {
          backgroundImage: `url(${BackgroundImageNew})`,
          backgroundPosition: "left",
          width: "100%",
          backgroundSize: "cover",
          paddingTop: "4rem",
        };
      }
    };

    const { utm_source, utm_medium, utm_campaign } = this.state;
    let param1, param2, param3;
    param1 =
      utm_source && utm_source.length > 0 ? `&utm_source=${utm_source}` : "";
    param2 =
      utm_medium && utm_medium.length > 0 ? `&utm_medium=${utm_medium}` : "";
    param3 =
      utm_campaign && utm_campaign.length > 0
        ? `&utm_campaign=${utm_campaign}`
        : "";

    let whatsAppUrl = `https://api.whatsapp.com/send?phone=918588809864&text=Hi%20Classplus${param1}${param2}${param3}`;
    console.log(whatsAppUrl, "whatsAppUrl");

    return (
      <div
        className="landing grid--1"
        style={
          window.innerWidth > 600
            ? background()
            : { width: window.innerWidth > 600 ? "85%" : "100%" }
        }
      >
        <div
          className="landing__content"
          style={window.innerWidth < 600 ? background() : { width: "85%" }}
        >
          {
            //   <div className="landing__content--main">
            //   <div>
            //     Launch <span style={{ color: "#fbd449" }}>your</span> online{" "}
            //   </div>
            //   <div>
            //     <span style={{ color: "#fbd449" }}>teaching platform</span>{" "}
            //     easily.
            //   </div>
            // </div>
            // <div className="landing__content--sub">
            //   Take a step towards building a self-reliant India by taking your
            //   coaching online!
            // </div>
          }
          {
            // <div
            //   className="landing__content--sub landing__content--sub--hashtag"
            //   style={{ marginTop: "1rem" }}
            // >
            //   #FightAgainstCorona
            // </div>
            // <div className="landing__content--sub  landing__content--sub--prompt ">
            //   Go Digital Now!
            // </div>
          }
          {
            // <div
            //   className={window.innerWidth < 600 ? "centerAlignmentNew" : ""}
            // >
            //   {window.innerWidth > 600 ? (
            //     <div className="whatsappBtn btn btn--bookADemo form__button instantFix2">
            //       <a
            //         href={whatsAppUrl}
            //         target="_blank"
            //         className="whatsappAnchor"
            //       >
            //         <img
            //           alt=""
            //           src={require("../images/whatsapp.svg")}
            //           style={{ height: "20px", width: "20px" }}
            //         />
            //         <p style={{ paddingLeft: "8px" }}>Book Your App Now</p>
            //       </a>
            //     </div>
            //   ) : (
            //     <ScrollTo>
            //       {({ scrollTo }) => (
            //         <button
            //           className="whatsappBtn whatsappAnchor form__button btn btn--bookADemo instantFix"
            //           onClick={() => {
            //             window.innerWidth > 600
            //               ? window.innerWidth > 1400
            //                 ? window.innerWidth > 1600
            //                   ? scrollTo({ y: 4600, smooth: true })
            //                   : scrollTo({ y: 3900, smooth: true })
            //                 : scrollTo({ y: 4100, smooth: true })
            //               : window.innerWidth < 1300
            //               ? window.innerWidth < 600
            //                 ? scrollTo({ y: 5750, smooth: true })
            //                 : scrollTo({ y: 3600, smooth: true })
            //               : scrollTo({ y: 3800, smooth: true });
            //           }}
            //         >
            //           Book your App Now
            //         </button>
            //       )}
            //     </ScrollTo>
            //   )}
            // </div>
          }
          {
            //  <div className="landing__content--tnc">
            //   <sup>*</sup>T&C applied
            // </div>
          }

          {
            // <img
            //   src={Illustrate}
            //   alt="Classplus"
            //   className="landing__Image"
            // />
          }
          {
            //    <img
            //   src={Illustrate}
            //   alt="Classplus"
            //   className="landing__Image"
            //  />
          }
          {
            //  <div style={{"display":"flex","flexDirection":"column","alignItems":"center"}}>
            //   <img src={NewLogo} alt="Classplus" className="landing__Image" />
            //   <div style={{fontSize:"30px","color":"#fff",margin:"25px auto 15px"}}>
            //   Bhopal Chapter
            //   </div>
            //   <button className="btn btn--bookADemo form__button" style={{width:"40%"}} onClick={()=>window.open("http://teachfest.classplusapp.com?utm_source=tutorsSite&utm_medium=web&utm_campaign=TeachFest.bhopal")}>
            //     Register Now
            //   </button>
            // </div>
          }
        </div>
        <div className="landing__form">
          <div className="landing__form--header">
            <div className="landing__form--header">
              <div className="landing__form--header--heading" id="form">
                Want to grow your revenue by 10x?
              </div>
              <div className="landing__form--header--content green--text">
                Request a free demo to see how technology can help you grow your
                online business
              </div>
            </div>
          </div>
          <div>
            <Form source="landing" />
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
