import React from "react";
import Ganguly from '../images/ganguly.png'
//import { Modal } from 'semantic-ui-react';
export const LifeStory = (props) => {
  // const videoIframe = () => {

  //     return (

  //         <Modal trigger={<div className="LifeStory__video">
  //             <button id="play-video" className="video-play-button cursor-free" onClick={videoIframe} >
  //                 <span></span>
  //             </button>

  //             <div id="video-overlay" className="video-overlay">
  //                 <button className="video-overlay-close">&times;</button>
  //             </div>
  //         </div>} basic size='small'>

  //             <Modal.Content>
  //                 <div className="closeX">

  //                 </div>
  //                 <div className="iframeContainer">
  //                     <iframe src="https://www.youtube.com/embed/T1ELADVz2Mo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="iframeVideo" title="Classplus Video"></iframe>

  //                 </div>
  //             </Modal.Content>

  //         </Modal>

  //     )
  // }

  return (
    <div className="LifeStory">
      <div className="LifeStory--text">
        <div className="LifeStory__heading heading--mega">
          {props && props.celebrity
            ? "A special message from a Special Teacher"
            : "Life Story of a Coaching Owner to ‘Meet, Mentor Dada!"}
        </div>
        {props && props.celebrity == false && (
          <div className="LifeStory__content p--medium green--text">
            We understand all the struggles of a teacher, we understand the
            difficulties that a coaching owner faces in starting and in running
            a coaching center. This video is a dedication to those efforts. Hats
            off!!
          </div>
        )}
      </div>
      {
        //    videoIframe()
      }
      <div className="marginTop3">
        {/* <div className="mask-image custom-image">
          <img
            src={Ganguly}
            className="img-fluid"
            alt="about"
          />
          <div className="item-icon video-promo-content custom-content">
            <a
              href="https://www.youtube.com/watch?v=Gfj4A8TuBHk"
              className="popup-youtube video-play-icon text-center m-auto"
            >
              <span className="ti-control-play"></span>{" "}
            </a>
          </div>
        </div> */}
        <iframe style={{width: "100%", maxWidth:"538"}} height="315" src="https://www.youtube.com/embed/Gfj4A8TuBHk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      {
        //<img src={} alt="VideoPlay" className=""/>
      }
    </div>
  );
};

export default LifeStory;
