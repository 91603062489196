import React, { Component } from "react";
import close from "../images/Close.svg";

class VideoModal extends Component {
  render() {
    return (
      <div className="VideoModal">
        <div className="VideoModal__Container">
          <div className="VideoModal__Container--Head">
            <p>Video</p>
            <img alt="close" onClick={this.props.toggleVideo} src={close} />
          </div>
          <div className="VideoModal__Container--Body">
            <iframe
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src={`https://www.youtube.com/embed/${this.props.embedLink}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoModal;
