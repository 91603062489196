import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import VirtualizedSelect from 'react-virtualized-select'
import VideoModal from './videoModal'

class Forms extends Component {
	constructor() {
		super();
		this.state = {
			Name: '',
			MobileNumber: '',
			Email: "",
			EmailValidation: true,
			City: "",
			Identity: '',
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
			success: false,
			countryOptions: [],
			isChecked: true,
			showVideoModal: false
		};
	}
	
	toggleVideoModal = () =>{
		this.setState({
		  showVideoModal: !this.state.showVideoModal
		})
	  }

	componentDidMount = () => {
		const vars = {};
		const parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
			vars[key] = value;
		});
		this.handleCityApi();
		this.setState({
			utm_source: vars['utm_source'],
			utm_medium: vars['utm_medium'],
			utm_campaign: vars['utm_campaign']
		});
	};

	parseAPIData = (data) => {
		let options = data.map((el) => ({ key: el.id, text: el.city, value: el.id }));
		// console.log('options', options);
		return options;
	};

	handleCityApi = () => {
		fetch(
			//'http://sales-incentive.classplus.co/newapi/saveonlineleads.php',
			'https://crm.classplus.co/su/affiliate/state?city=1',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
				// body: JSON.stringify(payload)
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				let countries = this.parseAPIData(data.data.city)
				let countryOptions = countries.map(obj => {
					return {
						"key": obj.key,
						"label": obj.text,
						"value": obj.value
					}
				})
				this.setState({ countryOptions: countryOptions });
			})
			.catch((err) => alert('Please Enter Valid Details'));
	};

	nameChange = (e) => {
		this.setState({
			Name: e.target.value
		});
	};

	mobileChange = (e) => {
		const re = /^[0-9\b]+$/;

		if (e.target.value === '' || re.test(e.target.value)) {
			if (e.target.value.length <= 10) {
				this.setState({
					MobileNumber: e.target.value
				});
			}
		}
	};
	emailChange = (e) => {
		const emialvalid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
		if (e.target.value === "" || emialvalid.test(e.target.value)) {
			this.setState({
				Email: e.target.value,
				EmailValidation: true
			})
		}
		else {
			this.setState({
				Email: e.target.value,
				EmailValidation: false
			})
		}

	}
	// city is putted in email. As per api demand.
	// cityChange = (e, data) => {
	// 	let selectedCity = this.state.countryOptions.filter((country) => {
	// 		return country.value === data.value;
	// 	})[0];
	// 	this.setState({
	// 		City: selectedCity
	// 	});
	// };

	identity = (e) => {
		this.setState({
			Identity: e.target.value
		});
	};

	handleCheck = () => {
		this.setState({
			isChecked: !this.state.isChecked
		})
	}

	formData = (e) => {
		e.preventDefault();
		if (this.state.MobileNumber.length < 10) {
			alert('Please Enter valid 10 digit mobile number');
		} else if (this.state.EmailValidation === false) {
			alert("Please enter valid Email");
		} else {
			const body = { ...this.state, appointmentDate: '' };
			// console.log("body",body);
			// fetch('https://hooks.zapier.com/hooks/catch/4460305/vr6ciu/', {
			//     method: 'POST',
			//     headers: {
			//         // "Content-Type": 'application/json',
			//     },
			//     body: JSON.stringify(body),
			// }).then(
			//     //  res => console.log(res, 'palash reesp')

			//     this.successPopUp(),
			//     this.clearFormData()

			// )
			//     .catch(err => alert("Please Enter Valid Details")
			//     );

			let payload = {
				type: 'store_lead',
				coachingName: this.state.Name,
				city: this.state.City.label,
				mobileNumber: this.state.MobileNumber,
				email: this.state.EmailValidation ? this.state.Email : "",
				campaignName: this.state.utm_campaign,
				utmSource: this.state.utm_source,
				utmMedium: this.state.utm_medium,
				isOptIn: this.state.isChecked ? 1 : 0,
			};
			fetch(
				//'http://sales-incentive.classplus.co/newapi/saveonlineleads.php',
				'https://crm.classplus.co/su/website',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}
			)
				.then(
					//  res => console.log(res, 'palash reesp')

					this.successPopUp(),
					this.clearFormData(),
					setTimeout(() => {
						this.toggleVideoModal()
					}, 500)
				)
				.catch((err) => alert('Please Enter Valid Details'));
		}
	};

	successPopUp = () => {
		this.setState({
			success: true
		});
	};

	clearFormData = () => {
		this.setState({
			Name: '',
			MobileNumber: '',
			City: '',
			Identity: '',
			Email: ""
		});
	};

	// clearPopup = () => {
	//     //alert("Success");
	//     console.log("Success3")
	//     console.log(this.state.success, "Success3")
	//     this.setState({
	//         success: false
	//     })
	//     console.log(this.state.success,"Success4")
	// }
	render() {
		const { utm_source, utm_medium, utm_campaign } = this.state;
		let param1, param2, param3;
		param1 = utm_source && utm_source.length > 0 ? `&utm_source=${utm_source}` : '';
		param2 = utm_medium && utm_medium.length > 0 ? `&utm_medium=${utm_medium}` : '';
		param3 = utm_campaign && utm_campaign.length > 0 ? `&utm_campaign=${utm_campaign}` : '';
		const dateObj = new Date();
		let LEAD_ID = dateObj.getTime();
		let whatsAppUrl = `https://api.whatsapp.com/send?phone=918588809864&text=Hi%20Classplus${param1}${param2}${param3}`;

		return (
			<div style={{ width: '100%' }}>
				{this.state.success == true ? (
					<div className="successPopup successOut">
						<img src={`https://opicle360.com/p.ashx?o=1240&e=649&f=img&udid=${LEAD_ID}`} width="1" height="1" border="0" />
						<div className="successPopup__content">
							We have registered your response successfully and will get back to you soon Thank you!
						</div>
					</div>
				) : (
						<div />
					)}
				<form className="form">
					<input
						type="text"
						className="form__input"
						placeholder="Your Name *"
						required
						onChange={this.nameChange}
						value={this.state.Name}
					/>
					<input
						type="text"
						className="form__input"
						placeholder="Your Mobile Number *"
						required
						onChange={this.mobileChange}
						value={this.state.MobileNumber}
					/>
					<input
						type="email"
						className="form__input"
						placeholder="Your Email"
						onChange={this.emailChange}
						value={this.state.Email}
					/>


					{/* <input
            type="text"
            className="form__input"
            placeholder="Your city *"
            required
            onChange={this.cityChange}
            value={this.state.City}
          /> */}
					{/*<Dropdown
						style={{ border: '1px solid #979797' }}
						className="form__input form__input--2"
						placeholder="Your city *"
						fluid
						search
						selection
						options={this.state.countryOptions}
						onChange={this.cityChange}
						value={Object.keys(this.state.City).length ? this.state.City.key : ''}
					/>*/}
					<VirtualizedSelect
						className="cityDropdown"
						style={{ border: "none", marginTop: "4px" }}
						placeholder="Your city *"
						clearable={false}
						maxHeight={150}
						options={this.state.countryOptions}
						onChange={(City) => this.setState({ City })}
						value={this.state.City}
					/>
					{/* <div className="flexrow" >
                            <input type="text" className="form__input form__input--2" placeholder="Your city *" required onChange={this.cityChange} value={this.state.City}/>


                            <select className="select-css" onChange={this.identity} value={this.state.Identity}>
                                <option value="" disabled defaultValue>Who are you?</option>
                                <option value="Tutor">Tutor</option>
                                <option value="Student">Student</option>
                                <option value="Parent">Parent</option>

                            </select>
						</div> */}
					<label>
						<input type="checkbox" defaultChecked="checked" checked={this.state.isChecked} onChange={this.handleCheck} ></input>
						<span className="checkmark">I agree to receive messages and notifications on whatsapp</span>
					</label>

					<button
						type="submit"
						className="btn btn--bookADemo form__button"
						style={{ marginBottom: '2.4rem' }}
						onClick={this.formData}
					>
						Book a Free Demo
					</button>

					{this.state.showVideoModal && <VideoModal toggleVideo={this.toggleVideoModal} embedLink="wmgGv6TrJcs"/>}
					{window.innerWidth < 480 && this.props.source == 'contact' ? (
						''
					) : (
							// <div>
							//   <hr></hr>
							//   <div className="whatsappBtn form__button btn btn--bookADemo">
							//     <a
							//       href={whatsAppUrl}
							//       target="_blank"
							//       className="whatsappAnchor"
							//     >
							//       <img
							//         alt=""
							//         src={require("../images/whatsapp.svg")}
							//         style={{ height: "20px", width: "20px" }}
							//       />
							//       <p style={{ paddingLeft: "8px" }}> Talk to us immediately </p>
							//     </a>
							//   </div>
							// </div>
							''
						)}
				</form>
			</div>
		);
	}
}

export default Forms;
