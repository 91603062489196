import React, { Component } from 'react';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Footer from './components/Footer';
import DetailedFeatures from './components/DetailedFeatures';
import TnC from './components/TnC';
import Policy from './components/Policy';
import ScrollToTop from './ScrollToTop';
import './App.scss';


import { BrowserRouter, Switch, Route ,Redirect} from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <div className="App fade-in">
      
       <BrowserRouter basename="/lp/store">
          <Navigation />
          <ScrollToTop>
        <Switch>
           
              <Route exact path="/" component={Home} />
              <Route exact path="/features" component={DetailedFeatures} />
              <Route exact path="/tnc" component={TnC} />
              <Route exact path="/policy" component={Policy} />
              <Route render={() => <Redirect to="/" />} />

         
        </Switch>
          </ScrollToTop>
          <Footer />
        </BrowserRouter>
       

      
      </div>
    );
  }
}

export default App;
