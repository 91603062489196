import React from "react";

import SVGForbes from "../images/SVGForbes.webp";
import SVGedex from "../images/WEBPedex.webp";
import SVGmoneyControl from "../images/SVGmoneyControl.webp";
import SVGtoi from "../images/ToiLogo.jpg";
import SVGFirstPost from "../images/SVGFirstPost.webp";
import SVGyourstory from "../images/SVGyourstory.webp";
import Background2 from "../images/background2.svg";

export const News = () => {
  const backgroundStyle = {
    backgroundImage: `url(${Background2})`,
    backgroundPosition: "center"
  };
  return (
    <div className="News" style={backgroundStyle}>
      <div className="News__header">
        <div className="News__header--heading heading--medium">
          Classplus in the Media
        </div>
        <div className="News__header--content p--medium green--text">
          Our mission and vision has inspired national and international media
          to feature us in their stories on a regular basis.
        </div>
      </div>
      <div className="News__list grid--4">
        <div className="News__list--item">
          <div className="News__list--item">
            <img
              src={SVGForbes}
              alt="circled"
              className="News__list--item--img"
            />

            <p className="News__list--item--content p--small">
              <i>
                "The startup makes it easier for tutors to manage students and
                assignments through an app"
              </i>
            </p>
          </div>
        </div>
        <div className="News__list--item">
          <div className="News__list--item">
            <img
              src={SVGmoneyControl}
              alt="circled"
              className="News__list--item--img"
            />

            <p className="News__list--item--content p--small">
              <i>
                {" "}
                "How Classplus is helping tutors manage their classes through an
                app"
              </i>
            </p>
          </div>
        </div>
        <div className="News__list--item">
          <div className="News__list--item">
            <img
              src={SVGFirstPost}
              alt="circled"
              className="News__list--item--img"
            />

            <p className="News__list--item--content p--small">
              <i>
                {" "}
                "Startup Classplus helps tutors to manage students, online
                payments and more through an app"
              </i>
            </p>
          </div>
        </div>
        <div className="News__list--item">
          <div className="News__list--item">
            <img
              src={SVGedex}
              alt="circled"
              className="News__list--item--img"
              style={{ marginLeft: "1rem" }}
            />

            <p className="News__list--item--content p--small">
              <i>
                "Why Classplus is an app that every tuition master needs to use"
              </i>
            </p>
          </div>
        </div>
        <div className="News__list--item">
          <div className="News__list--item">
            <img
              src={SVGtoi}
              alt="circled"
              className="News__list--item--img toiLogo"
              style={{ width: "96px", height: "48px" }}
            />

            <p className="News__list--item--content p--small">
              <i>
                "MY STARTUP IDEA: Classplus is enabling coaching institutes to
                go online"
              </i>
            </p>
          </div>
        </div>
        <div className="News__list--item">
          <div className="News__list--item">
            <img
              src={SVGyourstory}
              alt="circled"
              className="News__list--item--img"
              style={{ marginLeft: "1rem" }}
            />

            <p className="News__list--item--content p--small">
              <i>
                "How Classplus aims to simplify tutoring, give students new-age
                classroom experience"
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
