import React,{Component} from 'react'
import CarouselVideo from '../images/PNGStore.png';
import CarouselVideoSmall from '../images/StoreMobilepng.png';
//import BackgroundImage from '../images/BigStore.svg';
class Carousel extends Component {
    


    render() {


        // const sectionStyle = {
        //     width: "100%",
        //     //height: "700px",
        //     backgroundImage: "url(" + { BackgroundImage } + ")",
        //     backgroundAttachment : 'fixed',
        //     backgroundPosition: 'center',
        //     backgroundRepeat:'no-repeat',
        //     backgroundSize:'cover'
        // };
       
    return (
       
        <div className="Carousel grid--2">
            <div className="Carousel__pic">
                <img src={CarouselVideo} alt="Carousel Mega" className="Carousel__pic--img"/>
               
                    <img src={CarouselVideoSmall} alt="Carousel Mega" className="Carousel__pic--img--small" />
                
            </div>
            <div className="Carousel__content">
                <div className="Carousel__content--heading heading--medium fontStore">
                   Your Own Online Education Store
                </div>
                <div className="Carousel__content--content">
                    <p className="p--medium Carousel__content--content--text green--text">
                        Store is a one stop platform wherein tutors, coaching owners and online educators can publish and monetize courses, tests, videos, notes and much more via an online learning app by their own brand name.<br /><br />

It is time you become a national brand. <br/><br/>
                        It is time you grow your business by 2000%.<br /><br />
It is time you change how you attract new students!

</p>

                    

                </div>
               
            </div>

           
            
        </div>

           
           

          
           
      
    )
}
}

export default Carousel